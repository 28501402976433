<template>

<div class="contract common-page">

	<div class="page-header fx-vb">
      <div class="btn-act back" @click="pageBack">
      </div>
      <h1 class="page-title">
        Contact
      </h1>
      <div class="btn-act"></div>
  </div>
	<div class="main">
		<h1>Contact Email</h1>
		<p>{{ email }}</p>
	</div>
</div>
</template>

<script>
import  "./index.less";
export default {
	name: "Contract",
	data: function() {
		return {
			email:"business@novellettes.com"
		};
	},
	methods: {
		pageBack() {
			this.$router.push({
				name: 'HomePage'
			})
		}
	}
}
</script>

<style lang="less">
.common-page .page-header {
	// background-color: #FF5E5E;
 }
 .main{
	padding: .42667rem;
	> h1{
			font-size: .64rem;
			color: white;
			font-weight: bold;
			margin-bottom: .24rem
		}
	> p{
			font-size: .48rem;
			color: white;
			margin-bottom: .64rem
		}
 }
</style>